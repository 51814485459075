/* .card-group {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 40%;
  height: 40vh;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  border: none;
}

.large {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.fourCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 10px;
  padding: 20px;
}
.singleImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  padding: 20px;
}
.one {
  background-color: #ebf6ee;
}
.two {
  background-color: #f4f5f7;
}
.three {
  background-color: #ebd6e7;
}
.four {
  background-color: #daedf9;
} */
.card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  width: 40%;
  height: 40vh;
  background-color: #ccc;
  /* Add any other styling you want for the cards */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  border: none;
}

.large {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.fourCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 10px;
  /* background-color: aqua; */
  padding: 20px;
}

.singleImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  /* row-gap: 20px;
  column-gap: 10px; */
  /* background-color: aqua; */
  padding: 20px;
}

.one {
  background-color: #ebf6ee;
  cursor: pointer;
}

.images:hover {
  transform: scale(1.2);
  z-index: 999999;
  transition: all ease 1s;
}
.two {
  background-color: #fff4a3;
  /* background-color: #f4f5f7; */
  cursor: pointer;
}

.three {
  background-color: #ebd6e7;
  cursor: pointer;
}

.four {
  background-color: #96d4d4;
  /* background-color: #daedf9; */
  cursor: pointer;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .card {
    width: 80%; /* Adjust the width for smaller screens */
  }

  .large {
    width: 90%; /* Adjust the width for smaller screens */
  }
  .fourCard {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .one {
    width: 45%;
    padding: 10px;
  }
  .two {
    width: 45%;
    padding: 10px;
  }
  .three {
    width: 45%;
    padding: 10px;
  }
  .four {
    width: 45%;
    padding: 10px;
  }
  .fourCard {
    justify-content: center;
    column-gap: 20px;
  }
  .product-title {
    font-size: 15px !important;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%; /* Adjust the width for even smaller screens */
  }

  .large {
    width: 100%; /* Adjust the width for even smaller screens */
  }
  .one {
    width: 46%;
  }
  .two {
    width: 46%;
  }
  .three {
    width: 46%;
  }
  .four {
    width: 46%;
  }
  .product-title {
    font-size: 10px !important;
    font-weight: bold;
  }
  .card {
    height: 200px;
  }
}
.product-title {
  text-align: center;
}
