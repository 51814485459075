.card-main {
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  &.ant-card-body {
    padding: 0px !important;
  }

  &.ant-card-bordered .ant-card-cover {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.title {
  &.ant-card-meta {
    display: flex;
    justify-content: center;
  }
  &.ant-card-body {
    padding: 0px !important;
  }
}

/* CardWithHoverButton.css */

.custom-card {
  width: 300px;
}

/* product group */
.SingleProduct-main-div {
  width: 100%;
  padding: 20px;
  display: flex;
  /* column-gap: 20px; */
  justify-content: space-evenly;
  align-items: center;
  /* @media only screen and (min-width: 780px) and (max-width: 1023px) {
    flex-direction: column;
    display: flex;
    width: 100%;
    row-gap: 10px;
  } */
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    row-gap: 10px;
  }
}
.CardContent {
  width: 30%;
  height: 400px;
  position: relative;
  background-color: grey;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
}
.div-image {
  width: 60%;
  height: 100%;
  display: flex;
  /* align-items: flex-start; */
  justify-content: flex-start;
  background-color: aqua;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    height: 70%;
  }
}

.p-img {
  width: 100%;
  /* height: 70%; */
  /* background-size: contain;
  background-repeat: no-repeat; */
  object-fit: cover;
  /* display: flex;
  align-items: center;
  justify-content: center; */

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
}

.side-section {
  background: darkblue;
  width: 40%;
  display: flex;
  justify-content: center;
  color: white;
  height: 30%;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
}
.metaa {
  &.ant-card-body {
    padding: 0px !important;
  }
}
