.classic-page {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.header {
  background-color: #3498db;
  color: white;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
}

.main-content section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

h3 {
  color: #3498db;
  margin-top: 15px;
  margin-bottom: 5px;
}

.contact-info p {
  margin: 5px 0;
}

.footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #f4f4f4;
}

@media (max-width: 600px) {
  .classic-page {
    padding: 10px;
  }

  .main-content section {
    padding: 15px;
  }
}
.privacy-goals h3 {
  margin-top: 0;
}

.privacy-goals ul {
  padding-left: 20px;
}

.privacy-goals li {
  margin-bottom: 5px;
}
