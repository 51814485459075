.react-multi-carousel-list {
  width: 100%;
}

.container {
  position: relative;
  /* max-width: 800px;
    margin: 0 auto; */
}

.container img {
  vertical-align: middle;
  object-fit: contain;
}

.container .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
.main-div {
  @media screen and (max-width: 765px) {
    height: 350px !important;
  }
  /* @media screen and (max-width: 480px) {
    height: 300px !important;
  } */
}
.auto-mid-div {
  flex-direction: column;
  @media screen and (max-width: 756px) {
    /* height: 200px; */
  }
}
.auto-image {
  width: 100%;
  height: 80%;
  object-fit: contain;
  /* max-height: 80%;
  min-height: 80%; */
  @media screen and (max-width: 375) {
    /* height: 100px; */
    /* height: 200px; */
  }
}
.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60%;
}
.view-button {
  padding: 10px 30px;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 30px;
  /* background-color: #146cda; */
  /* background-color: #1800be; */
  background-color: white;
  color: black;
  border: 1px solid black;
  font-weight: 600;
  transition: all 0.5s ease-in;
  :hover {
    border: 1px solid white;
    /* background-color: #555; */
    /* background-color: #ddd; */
    /* background-color: #1800be; */
    background-color: black;
    color: white;
    /* filter: grayscale(50%); */
  }
}

.product-title {
  font-size: 15px;
  font-weight: bold;
}
.top-product {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
